import { useRef, useState } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { TextFieldJackState } from "../../../../../components/inputs/textfield";
import DropdownSelection from "./DropdownSelection";
import SpinningLoading from "./SpinningLoading";
import styles from "./styles.module.css";
import { companyTagValidator } from "../../../helpers";
import {
  LOADING_COMPANY,
  CLOSED_DROPDOWN,
  RECENT_TRANSACTIONS,
  ERROR_NOT_FOUND,
  ERROR_INVALID,
  FOUND_COMPANY,
  J2J_EVENT_CLICK_HELP_JACKID,
} from "../../../constants";
import { isEmpty } from "lodash";
import { useClickOutside } from "../../../../../universalFunctions/useClickOutside";
import SelectedCompany from "../SelectedCompany";
import { useModalHook } from "../../../../../components/Modals";
import JackTransferInfoModal from "../../JackTransferInfoModal";
import { eventsTracker } from "../../../../../universalFunctions/events";

const CompanyTagInput = ({
  isLoadingCompany = false,
  value = "",
  options = [],
  foundCompany = {},
  selectedCompany = {},
  onChange = () => {},
  onSelect = () => {},
  resetSelectedCompany = () => {},
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [activeAutoFocus, setActiveAutoFocus] = useState(false);

  const {
    isOpen: isOpenJackTransferInfoModal,
    open: openJackTransferInfoModal,
    close: closeJackTransferInfoModal,
  } = useModalHook();

  const isFoundCompany = !isEmpty(foundCompany);
  const isEmptyValue = value === "";
  const isValidValue = companyTagValidator(value);
  const isTyping = !!value && !isValidValue;

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleClickInfo = () => {
    openJackTransferInfoModal();
    eventsTracker(J2J_EVENT_CLICK_HELP_JACKID, {
      parameters: { page_title: "jack_transfer_recipient_account" },
      value: "select_recipient_balance_transfer",
    });
  };

  const handleSelect = (company) => {
    onSelect(company);
    setActiveAutoFocus(true);
    handleBlur();
  };

  const fieldRef = useRef(null);
  useClickOutside({ ref: fieldRef, clickOutside: handleBlur });

  const dropdownType = () => {
    if (isLoadingCompany) return LOADING_COMPANY;
    if (isFocused) {
      if (isEmptyValue) return RECENT_TRANSACTIONS;
      if (isValidValue) {
        if (!isFoundCompany) return ERROR_NOT_FOUND;
        return FOUND_COMPANY;
      }
      return ERROR_INVALID;
    }
    return CLOSED_DROPDOWN;
  };

  const rightIcon = () => {
    if (isLoadingCompany || isTyping) return <SpinningLoading />;
    if (isValidValue) {
      if (!isFoundCompany) {
        return <JackIcons name="close-circle" fill={jackColors.redE7} />;
      }
      return <JackIcons name="checkmark-circle-2" fill={jackColors.green06} />;
    }
    return (
      <JackIcons
        name="question-mark-circle"
        fill={jackColors.neutral800}
        style={{ cursor: "pointer" }}
        onClick={handleClickInfo}
      />
    );
  };

  const hasSelectedCompany = !isEmpty(selectedCompany);

  const inputColor = isLoadingCompany
    ? jackColors.neutral700
    : jackColors.neutral900;

  if (hasSelectedCompany) {
    return <SelectedCompany company={selectedCompany} onClick={handleSelect} />;
  }

  return (
    <div ref={fieldRef} className={styles.companyTagInput}>
      <TextFieldJackState
        required
        value={value}
        label="Jack ID"
        placeholder="Enter Jack ID"
        maxLength={10}
        autoFocus={activeAutoFocus}
        style={{ marginTop: "32px", marginBottom: "0px" }}
        icon={<JackIcons name="dollar" fill={inputColor} />}
        iconRight={rightIcon()}
        iconLeftStyle={{ translate: "-4px -1px" }}
        textFieldInputStyle={{
          color: inputColor,
          translate: "0px -1px",
        }}
        onChange={onChange}
        onFocus={handleFocus}
      />

      <DropdownSelection
        type={dropdownType()}
        options={options}
        onSelect={handleSelect}
      />

      <JackTransferInfoModal
        isOpen={isOpenJackTransferInfoModal}
        toggle={closeJackTransferInfoModal}
      />
    </div>
  );
};

export default CompanyTagInput;
