import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import styles from "./styles.module.css";

const JackTransferButtonNav = ({
  isLoadingCancel = false,
  isLoadingConfirm = false,
  isDisabledCancel = false,
  isDisabledConfirm = false,
  cancelText = "Back",
  confirmText = "Continue",
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  return (
    <div className={styles.buttonNavContainer}>
      <ButtonJack
        type="outline"
        isLoading={isLoadingCancel}
        disabled={isDisabledCancel}
        leftIcon={
          <JackIcons
            name="arrow-back-outline"
            fill={
              isDisabledCancel ? jackColors.neutral500 : jackColors.neutral900
            }
          />
        }
        onClick={onCancel}
      >
        {cancelText}
      </ButtonJack>
      <ButtonJack
        isLoading={isLoadingConfirm}
        disabled={isDisabledConfirm}
        rightIcon={
          <JackIcons
            name="arrow-forward-outline"
            fill={
              isDisabledConfirm ? jackColors.neutral600 : jackColors.neutral900
            }
          />
        }
        onClick={onConfirm}
      >
        {confirmText}
      </ButtonJack>
    </div>
  );
};

export default JackTransferButtonNav;
