import { useRouter } from "next/router";
import { useHeader } from "../../../contexts/Layout/parent";
import { ProgressBarJack } from "../../../components/Stepper";
import { useEffect, useMemo } from "react";
import {
  CONFIRM_SUBMIT_TYPE,
  RECIPIENT_ACCOUNT_TYPE,
  TRANSFER_DETAILS_TYPE,
} from "../constants";

const defaultObjArgs = { onClose: () => {} };

const useJackTransferHeader = (args = defaultObjArgs) => {
  const { onClose } = args;

  const { query } = useRouter();
  const { form_type } = query;

  const { setHeader } = useHeader();

  const progressBarItems = useMemo(() => {
    return [
      {
        step: "Select Recipient",
        isActive: form_type === RECIPIENT_ACCOUNT_TYPE,
      },
      {
        step: "Input Details",
        isActive: form_type === TRANSFER_DETAILS_TYPE,
      },
      {
        step: "Confirm to Submit",
        isActive: form_type === CONFIRM_SUBMIT_TYPE,
      },
    ];
  }, [form_type]);

  useEffect(() => {
    setHeader({
      type: "close",
      middleComponent: (
        <ProgressBarJack arrayStep={progressBarItems} customLineLength={200} />
      ),
      style: { margin: 0, padding: "12px 32px" },
      onCloseProps: onClose,
    });
  }, [progressBarItems]);
};

export default useJackTransferHeader;
