import { useRouter } from "next/router";
import { useEffect } from "react";
import {
  CONFIRM_SUBMIT_TYPE,
  RECIPIENT_ACCOUNT_TYPE,
  TRANSFER_DETAILS_TYPE,
} from "../constants";

const useJackTransferDefaultQueryParams = () => {
  const { query, isReady, replace } = useRouter();

  const { form_type } = query;

  useEffect(() => {
    const validFormTypes = [
      RECIPIENT_ACCOUNT_TYPE,
      TRANSFER_DETAILS_TYPE,
      CONFIRM_SUBMIT_TYPE,
    ];

    const isValidFormType = !!form_type && validFormTypes.includes(form_type);

    if (isReady && !isValidFormType) {
      replace({ query: { ...query, form_type: RECIPIENT_ACCOUNT_TYPE } });
    }
  }, [form_type, isReady]);
};

export default useJackTransferDefaultQueryParams;
