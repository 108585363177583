import { Modal } from "reactstrap";
import styles from "./styles.module.css";
import { GothamRegular } from "../Text";

const Loader = ({ fill = "white" }) => {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.loader}
    >
      <path
        d="M80.5 40C80.5 34.7471 79.4654 29.5457 77.4552 24.6927C75.445 19.8396 72.4986 15.4301 68.7843 11.7157C65.0699 8.00138 60.6604 5.05501 55.8073 3.04482C50.9543 1.03463 45.7529 -2.2961e-07 40.5 0V8C44.7023 8 48.8634 8.8277 52.7459 10.4359C56.6283 12.044 60.1559 14.4011 63.1274 17.3726C66.0989 20.3441 68.456 23.8717 70.0641 27.7541C71.6723 31.6365 72.5 35.7977 72.5 40H80.5Z"
        fill={fill}
      />
    </svg>
  );
};

const FullScreenLoading = ({
  show = false,
  color = "white",
  loadingText = "",
}) => {
  return (
    <Modal centered isOpen={show}>
      <div className={styles.fullScreenLoading}>
        <div>
          <Loader fill={color} />
          <GothamRegular woFontColor className="font20" style={{ color }}>
            {loadingText}
          </GothamRegular>
        </div>
      </div>
    </Modal>
  );
};

export default FullScreenLoading;
