import { jackColors } from "../../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { NumberFieldJackState } from "../../../../../components/inputs/textfield";
import { formatCurrencyNoDecimal } from "../../../../../components/tools";

const TransferAmountInput = ({
  value = "",
  balance = 0,
  onChange = () => {},
}) => {
  const hasEnoughBalance = balance >= Number(value);

  return (
    <div style={{ marginTop: "10px" }}>
      <NumberFieldJackState
        noBorder
        autoFocus
        isCurrency
        isTransparent
        maxLength={16}
        value={value}
        onChange={onChange}
        style={{ margin: "0px" }}
        textFieldStyle={{ border: "none" }}
        textFieldInputStyle={{
          border: "none",
          color: jackColors.neutral900,
          fontSize: "20px",
          lineHeight: "24px",
          fontFamily: "GothamMedium",
        }}
        icon={
          <GothamMedium
            className="font20"
            style={{ color: jackColors.neutral900, translate: "-10px -0.5px" }}
          >
            IDR
          </GothamMedium>
        }
      />

      <div
        style={{
          width: "100%",
          marginTop: "8px",
          borderTop: `1px solid ${jackColors.neutral900}`,
        }}
      />

      {!hasEnoughBalance && (
        <GothamRegular
          woClassName
          woFontColor
          className="font12"
          style={{ color: jackColors.redE7, marginTop: "8px" }}
        >
          You cannot input more than your company balance.
        </GothamRegular>
      )}

      <GothamRegular
        woClassName
        woFontColor
        className="font12"
        style={{ color: jackColors.neutral700, marginTop: "8px" }}
      >
        Company balance: IDR {formatCurrencyNoDecimal(balance)}
      </GothamRegular>
    </div>
  );
};

export default TransferAmountInput;
