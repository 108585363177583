import { useState } from "react";
import { jackColors } from "../../../../assets/colors";
import styles from "./styles.module.css";
import CompanyTagInput from "./CompanyTagInput";
import {
  useFindCompany,
  useJackTransferData,
  useJackTransferNavigation,
  useJackTransferStoredData,
} from "../../hooks";
import { isEmpty } from "lodash";
import JackTransferButtonNav from "../JackTransferButtonNav";
import FullScreenLoading from "../../../../components/FullScreenLoading";
import {
  J2J_EVENT_CLICK_BACK,
  J2J_EVENT_CLICK_CONTINUE,
  J2J_EVENT_CLICK_GO_BACK,
  J2J_EVENT_CLICK_STAY_HERE,
  TRANSFER_DETAILS_TYPE,
} from "../../constants";
import JackTransferTitle from "../JackTransferTitle";
import ExitJackTransferModal from "../ExitJackTransferModal";
import { useModalHook } from "../../../../components/Modals";
import { eventsTracker } from "../../../../universalFunctions/events";

const RecipientAccount = ({ recentTransactions = [] }) => {
  const [companyTag, setCompanyTag] = useState("");

  const {
    isOpen: isOpenExitJackTransferModal,
    open: openExitJackTransferModal,
    close: closeExitJackTransferModal,
  } = useModalHook();

  const {
    isLoading: isLoadingContinue,
    onBack,
    onContinue,
  } = useJackTransferNavigation({
    backDestination: "back",
    continueDestination: TRANSFER_DETAILS_TYPE,
  });

  const { jackTransferData, setJackTransferData } = useJackTransferData();
  const { company: selectedCompany } = jackTransferData;

  const { company, isLoadingCompany } = useFindCompany({
    companyTag,
  });

  const { storeJackTransferData } = useJackTransferStoredData();

  const hasSelectedCompany = !isEmpty(selectedCompany);

  const handleBack = () => {
    openExitJackTransferModal();
    eventsTracker(J2J_EVENT_CLICK_BACK, {
      parameters: { page_title: "jack_transfer_recipient_account" },
      value: "select_recipient_balance_transfer",
    });
  };

  const handleCloseExitModal = () => {
    closeExitJackTransferModal();
    eventsTracker(J2J_EVENT_CLICK_STAY_HERE, {
      parameters: { page_title: "jack_transfer_recipient_account" },
      value: "close_select_recipient_balance_transfer",
    });
  };

  const handleChange = (value) => {
    if (isLoadingCompany) return;
    setCompanyTag(value.toLocaleUpperCase());
  };

  const handleSelect = (company) => {
    setJackTransferData((prev) => ({ ...prev, company }));
    storeJackTransferData({ ...jackTransferData, company });
  };

  const handleConfirmBack = () => {
    onBack();
    eventsTracker(J2J_EVENT_CLICK_GO_BACK, {
      parameters: { page_title: "jack_transfer_recipient_account" },
      value: "close_select_recipient_balance_transfer",
    });
  };

  const handleContinue = () => {
    onContinue();
    eventsTracker(J2J_EVENT_CLICK_CONTINUE, {
      parameters: { page_title: "jack_transfer_recipient_account" },
      value: "select_recipient_balance_transfer",
    });
  };

  const options = () => {
    const isFoundCompany = !isEmpty(company);
    const isEmptyValue = companyTag === "";
    if (isEmptyValue) return recentTransactions;
    if (isFoundCompany) return [company];
    return [];
  };

  return (
    <div className={styles.recipientAccount}>
      <JackTransferTitle
        title="Select a recipient"
        description="Enter the Jack ID of the company you are sending to."
      />

      <CompanyTagInput
        value={companyTag}
        options={options()}
        foundCompany={company}
        selectedCompany={selectedCompany}
        isLoadingCompany={isLoadingCompany}
        recentTransactions={recentTransactions}
        onChange={handleChange}
        onSelect={handleSelect}
      />

      <JackTransferButtonNav
        isDisabledConfirm={!hasSelectedCompany}
        onCancel={handleBack}
        onConfirm={handleContinue}
      />

      <FullScreenLoading
        show={isLoadingContinue}
        loadingText="Loading.."
        color={jackColors.neutral900}
      />

      <ExitJackTransferModal
        isOpen={isOpenExitJackTransferModal}
        toggle={handleCloseExitModal}
        onConfirm={handleConfirmBack}
      />
    </div>
  );
};

export default RecipientAccount;
