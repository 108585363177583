import { Fragment } from "react";
import { jackColors } from "../../../../../../assets/colors";
import { GothamRegular } from "../../../../../../components/Text";
import styles from "./styles.module.css";
import {
  CLOSED_DROPDOWN,
  ERROR_INVALID,
  ERROR_NOT_FOUND,
  J2J_EVENT_CLICK_RECENT_TRANSFER,
  LOADING_COMPANY,
  RECENT_TRANSACTIONS,
} from "../../../../constants";
import CompanyItem from "../../../CompanyItem";
import { eventsTracker } from "../../../../../../universalFunctions/events";

const DefaultText = ({ children, style = {} }) => {
  return (
    <GothamRegular
      className="font12"
      style={{ color: jackColors.neutral800, ...style }}
    >
      {children}
    </GothamRegular>
  );
};

const optionsTemplate = [
  {
    id: 0,
    name: "Placeholder",
    tag: "J123123X",
  },
];

const DropdownSelection = ({
  type = CLOSED_DROPDOWN, // closed | recent_transactions | found_company | error_invalid | error_not_found | loading
  options = optionsTemplate,
  onSelect = () => {},
}) => {
  const dropdownContent = () => {
    switch (type) {
      case ERROR_INVALID:
        return <DefaultText>Please input the 8 digits Jack ID</DefaultText>;

      case ERROR_NOT_FOUND:
        return (
          <DefaultText>
            We cannot find any company with this ID. Please check and try again.
          </DefaultText>
        );

      default:
        return (
          <Fragment>
            {type === RECENT_TRANSACTIONS && (
              <DefaultText
                style={{ color: jackColors.neutral700, marginBottom: "8px" }}
              >
                Recent transfer
              </DefaultText>
            )}

            {options?.map((option) => {
              const handleSelect = (option) => {
                onSelect(option);
                if (type === RECENT_TRANSACTIONS) {
                  eventsTracker(J2J_EVENT_CLICK_RECENT_TRANSFER, {
                    parameters: {
                      page_title: "jack_transfer_recipient_account",
                    },
                    value: "select_recipient_balance_transfer",
                  });
                }
              };

              return (
                <CompanyItem
                  key={option?.tag}
                  company={option}
                  onSelect={handleSelect}
                />
              );
            })}
          </Fragment>
        );
    }
  };

  const hideDropdown =
    type === CLOSED_DROPDOWN ||
    type === LOADING_COMPANY ||
    (type === RECENT_TRANSACTIONS && options?.length <= 0);

  if (hideDropdown) return null;

  return <div className={styles.dropdownSelection}>{dropdownContent()}</div>;
};

export default DropdownSelection;
