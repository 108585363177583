import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import imgBack from "../../../../assets/images/back-previous-page.svg";
import { useState } from "react";

const ExitJackTransferModal = ({
  isOpen = false,
  toggle = () => {},
  onConfirm = () => {},
}) => {
  const [isNavigating, setIsNavigating] = useState(false);

  const handleCancel = () => {
    if (isNavigating) return;
    toggle();
  };
  const handleConfirm = () => {
    onConfirm();
    setIsNavigating(true);
  };

  return (
    <ConfirmationModalJack
      modal={isOpen}
      img={imgBack}
      loading={isNavigating}
      title="Back to previous page?"
      text="By leaving this page, you will lose all your transfer progress"
      buttonTextLeft="Continue Transfer"
      buttonTextRight="Go Back"
      toggle={handleCancel}
      onClickLeft={handleCancel}
      onClickRight={handleConfirm}
    />
  );
};

export default ExitJackTransferModal;
