import { Fragment } from "react";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { jackColors } from "../../../../assets/colors";

const JackTransferTitle = ({ title = "", description = "" }) => {
  return (
    <Fragment>
      <GothamMedium className="font24" style={{ color: jackColors.neutral900 }}>
        {title}
      </GothamMedium>
      {description && (
        <GothamRegular
          style={{ color: jackColors.neutral700, marginTop: "8px" }}
        >
          {description}
        </GothamRegular>
      )}
    </Fragment>
  );
};

export default JackTransferTitle;
