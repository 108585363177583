import { useEffect } from "react";
import { useGetAuth } from "../../../contexts/AuthContext";
import useJackTransferStoredData from "./useJackTransferStoredData";
import { isEmpty } from "lodash";

const useStoreJackTransferDetails = ({
  canStore = false,
  data: jackTransferData,
}) => {
  const { amount, notes, category } = jackTransferData;

  const { user } = useGetAuth();
  const { mainBalance } = user ?? {};
  const { balance } = mainBalance ?? {};

  const { storeJackTransferData } = useJackTransferStoredData();

  const handleStore = (key, value) => {
    storeJackTransferData({ ...jackTransferData, [key]: value });
  };

  // To store amount
  useEffect(() => {
    if (!canStore) return;
    const timeout = setTimeout(() => handleStore("amount", amount), 400);
    return () => clearTimeout(timeout);
  }, [amount, balance, canStore]);

  // To store notes
  useEffect(() => {
    if (!canStore) return;
    const timeout = setTimeout(() => handleStore("notes", notes), 400);
    return () => clearTimeout(timeout);
  }, [notes, canStore]);

  // To store category
  useEffect(() => {
    if (!canStore) return;
    const hasCategory = !!category && !isEmpty(category);
    if (!hasCategory) return;
    handleStore("category", category);
  }, [category, canStore]);
};

export default useStoreJackTransferDetails;
