import { useState } from "react";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import CustomHoverInfo from "../../../../components/CustomHoverInfo/CustomHoverInfo";
import { GothamMedium } from "../../../../components/Text";
import { useGetAuth } from "../../../../contexts/AuthContext";
import styles from "./styles.module.css";
import { eventsTracker } from "../../../../universalFunctions/events";
import { J2J_EVENT_CLICK_JACK_ID } from "../../constants";

const CompanyTagCapsule = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [showCopyIndicator, setShowCopyIndicator] = useState(false);

  const { user } = useGetAuth();
  const { partner } = user ?? {};
  const { balance_tag } = partner ?? {};

  const handleCopy = () => {
    if (showCopyIndicator) return;
    eventsTracker(J2J_EVENT_CLICK_JACK_ID, {
      parameters: { page_title: "dashboard" },
      value: "dashboard",
    });
    setShowCopyIndicator(true);
    navigator.clipboard.writeText(balance_tag);
    setTimeout(() => {
      setShowCopyIndicator(false);
    }, 1000);
  };

  const hasBalanceTag = !!balance_tag;

  if (!hasBalanceTag) return null;

  return (
    <div
      id="company-tag-tour"
      className={styles.capsule}
      onClick={handleCopy}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <JackIcons
        name="dollar"
        fill={jackColors.neutral800}
        style={{ scale: "0.8" }}
      />
      <GothamMedium
        woFontColor
        className="font12"
        style={{ color: jackColors.neutral800 }}
      >
        {balance_tag}
      </GothamMedium>
      <CustomHoverInfo
        show={isHovered}
        rotateTrianglePoint="270deg"
        translateTrianglePoint={showCopyIndicator ? "32px 30px" : "47px 30px"}
        backgroundColor={jackColors.neutral900}
        style={{
          translate: `-0 -45px`,
          width: showCopyIndicator ? "70px" : "100px",
          backgroundColor: jackColors.neutral900,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <GothamMedium
          woFontColor
          className="font12"
          style={{ color: jackColors.neutral100 }}
        >
          {showCopyIndicator ? "Copied" : "Copy Jack ID"}
        </GothamMedium>
      </CustomHoverInfo>
    </div>
  );
};

export default CompanyTagCapsule;
