import { fetch } from "../../../tools/api";

const useJackTransferRecentTransactions = () => {
  const MAX_TRANSACTIONS = 3;

  const { data: recentTransactions } = fetch({
    url: "/balance_transfers/recent_destinations",
    defaultValue: [],
    formatter: (res) => {
      const data = res?.data ?? [];
      const formattedData = data
        ?.slice(0, MAX_TRANSACTIONS)
        ?.map(({ name, balance_tag }) => ({ name, tag: balance_tag }));
      return formattedData;
    },
  });

  return recentTransactions;
};

export default useJackTransferRecentTransactions;
