import { useRouter } from "next/router";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { CrossBorderSingleStatusLine } from "../../../../modals/smartActivityModal/crossBorderSingleModal/status";
import styles from "./styles.module.css";
import { isString } from "lodash";

export const WITHDRAW_SUCCESS_SUBMIT = "submit";
export const WITHDRAW_SUCCESS_CANCEL = "cancel";
export const WITHDRAW_SUCCESS_REJECT = "reject";
export const WITHDRAW_SUCCESS_APPROVE = "approve";
export const WITHDRAW_SUCCESS_RELEASE = "release";
export const WITHDRAW_SUCCESS_TRANSFER = "transfer";

const WithdrawSuccessScreen = ({ data }) => {
  const { amount, details } = data ?? {};

  const { query, push } = useRouter();
  const { status } = query;

  const recipientName = details?.account_name || "-";
  const accountNumber = details?.account_number || "-";
  const bankName = details?.bank_name || "-";

  const summaries = [
    {
      key: "Recipient",
      value: (
        <div className={styles.recipient}>
          <GothamRegular
            woFontColor
            className="font12"
            style={{ color: jackColors.neutral900 }}
          >
            {recipientName}
          </GothamRegular>
          <GothamRegular
            woFontColor
            className="font10"
            style={{ color: jackColors.neutral800 }}
          >
            {bankName} - {accountNumber}
          </GothamRegular>
        </div>
      ),
    },
    {
      key: "Withdraw amount",
      value: `IDR ${formatCurrencyNoDecimal(Number(amount))}`,
      valueStyles: { color: jackColors.neutral900, fontFamily: "GothamMedium" },
    },
  ];

  const handleClose = () => {
    push("/dashboard");
  };

  const titleDecider = () => {
    switch (status) {
      case WITHDRAW_SUCCESS_SUBMIT:
        return "You have submitted a withdrawal request!";
      case WITHDRAW_SUCCESS_CANCEL:
        return "This request has been cancelled";
      case WITHDRAW_SUCCESS_REJECT:
        return "This request has been rejected";
      case WITHDRAW_SUCCESS_APPROVE:
        return "You have approved this request!";
      case WITHDRAW_SUCCESS_RELEASE:
        return "Your money is on its way!";
      case WITHDRAW_SUCCESS_TRANSFER:
        return "Your transfer is on its way!";
      default:
        return "You have submitted a withdrawal request!";
    }
  };

  const iconDecider = () => {
    switch (status) {
      case WITHDRAW_SUCCESS_CANCEL:
      case WITHDRAW_SUCCESS_REJECT:
        return "close-circle";
      case WITHDRAW_SUCCESS_SUBMIT:
      case WITHDRAW_SUCCESS_APPROVE:
      case WITHDRAW_SUCCESS_RELEASE:
      case WITHDRAW_SUCCESS_TRANSFER:
      default:
        return "success-icon";
    }
  };

  const title = titleDecider();
  const iconName = iconDecider();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <JackIcons
          name={iconName}
          fill={jackColors.neutral900}
          style={{ width: "60px", height: "60px" }}
        />

        <GothamMedium
          className="font24"
          style={{
            color: jackColors.neutral900,
            marginTop: "32px",
            textAlign: "center",
          }}
        >
          {title}
        </GothamMedium>
        {/*
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral700, marginTop: "8px" }}
        >
          We'll let you know once your request has been reviewed.
        </GothamRegular>
        */}

        <div className={styles.summary}>
          <div className={styles.summaryTitle}>
            <div className={styles.summaryIcon}>
              <JackIcons
                name="withdraw"
                fill="white"
                style={{ scale: "0.8" }}
              />
            </div>
            <div>
              <GothamMedium
                className="font12"
                style={{ color: jackColors.neutral900 }}
              >
                Withdrawal
              </GothamMedium>
              <GothamRegular
                woFontColor
                className="font12"
                style={{ color: jackColors.neutral700, marginTop: "2px" }}
              >
                Main balance withdrawal
              </GothamRegular>
            </div>
          </div>

          <div className={styles.divider} />

          {summaries?.map((summary) => {
            const isStringValue = isString(summary?.value);
            return (
              <div key={summary?.key} className={styles.summaryItem}>
                <GothamRegular
                  woFontColor
                  className="font12"
                  style={{ color: jackColors.neutral800 }}
                >
                  {summary?.key}
                </GothamRegular>
                {isStringValue ? (
                  <GothamRegular
                    woFontColor
                    className="font12"
                    style={{
                      color: jackColors.neutral800,
                      ...summary?.valueStyles,
                    }}
                  >
                    {summary?.value}
                  </GothamRegular>
                ) : (
                  summary?.value
                )}
              </div>
            );
          })}
        </div>

        <div style={{ width: "100%", marginTop: "20px" }}>
          <CrossBorderSingleStatusLine isModal transaction={data} />
        </div>

        <ButtonJack
          style={{ marginTop: "32px", width: "100%" }}
          onClick={handleClose}
        >
          Close
        </ButtonJack>
      </div>
    </div>
  );
};

export default WithdrawSuccessScreen;
