import { isEmpty } from "lodash";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import {
  J2J_EVENT_CLICK_GO_BACK,
  J2J_EVENT_CLICK_SUBMIT,
  TRANSFER_DETAILS_TYPE,
} from "../../constants";
import {
  useJackTransferData,
  useJackTransferKickDueToMissingData,
  useJackTransferNavigation,
  useSubmitJackTransfer,
} from "../../hooks";
import JackTransferButtonNav from "../JackTransferButtonNav";
import JackTransferTitle from "../JackTransferTitle";
import styles from "./styles.module.css";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { CategoryIcon } from "../../../categoryComponents/general/components";
import { eventsTracker } from "../../../../universalFunctions/events";

const Label = ({
  children = null,
  icon = "",
  color = jackColors.neutral900,
  style = {},
  textStyle = {},
}) => {
  return (
    <div className={styles.label} style={style}>
      <JackIcons name={icon} fill={color} style={{ scale: "0.8" }} />
      <GothamMedium style={{ ...textStyle, color }}>{children}</GothamMedium>
    </div>
  );
};

const ConfirmSubmit = () => {
  const { jackTransferData } = useJackTransferData();
  const { company, amount, notes, category } = jackTransferData;

  const hasCategory = !isEmpty(category);

  useJackTransferKickDueToMissingData(jackTransferData);

  const { user } = useGetAuth();
  const { partner } = user ?? {};

  const { onBack } = useJackTransferNavigation({
    backDestination: TRANSFER_DETAILS_TYPE,
  });

  const { submitJackTransfer, isLoading: isLoadingSubmitJackTransfer } =
    useSubmitJackTransfer();

  const handleBack = () => {
    onBack();
    eventsTracker(J2J_EVENT_CLICK_GO_BACK, {
      parameters: { page_title: "jack_transfer_confirmation" },
      value: "balance_transfer_confirmation",
    });
  };

  const handleSubmit = () => {
    const payload = {
      destination_balance_tag: company?.tag,
      amount,
      note: notes,
    };

    if (hasCategory) payload.category_id = category?.id;

    eventsTracker(J2J_EVENT_CLICK_SUBMIT, {
      parameters: { page_title: "jack_transfer_confirmation" },
      value: "balance_transfer_confirmation",
    });

    submitJackTransfer(payload);
  };

  const transactionDetails = [
    { key: "From account", value: partner?.name || "-" },
    { key: "Transfer to", value: company?.name || "-" },
    hasCategory && {
      key: "Transaction Category",
      value: (
        <div className={styles.label} style={{ margin: "0px" }}>
          <CategoryIcon
            name={category?.icon}
            fill={category?.color}
            style={{ scale: "0.8" }}
          />
          <GothamRegular woFontColor style={{ color: jackColors.neutral900 }}>
            {category?.name}
          </GothamRegular>
        </div>
      ),
    },
  ].filter((detail) => detail);

  return (
    <div className={styles.confirmSubmit}>
      <JackTransferTitle title="Confirm to Submit" />

      <Label
        color={jackColors.neutral800}
        icon="swap-outline"
        style={{ marginTop: "32px" }}
        textStyle={{ fontFamily: "GothamBook" }}
      >
        Transfer balance
      </Label>

      <GothamMedium
        className="font24"
        style={{ color: jackColors.neutral900, marginTop: "16px" }}
      >
        IDR {formatCurrencyNoDecimal(amount)}
      </GothamMedium>

      <Label icon="coin">Transaction Details</Label>
      <div className={styles.divider} />

      {transactionDetails.map(({ key, value }, index) => {
        const isJSXValue = typeof value === "object";
        const isFirst = index === 0;
        return (
          <div
            key={key}
            className={styles.detail}
            style={{ marginTop: isFirst ? "24px" : "16px" }}
          >
            <GothamRegular woFontColor style={{ color: jackColors.neutral700 }}>
              {key}
            </GothamRegular>
            {!isJSXValue ? (
              <GothamRegular
                woFontColor
                style={{ color: jackColors.neutral900 }}
              >
                {value}
              </GothamRegular>
            ) : (
              value
            )}
          </div>
        );
      })}

      <div className={styles.divider} />
      <Label icon="sticky-note">Transfer notes</Label>

      <GothamRegular
        woFontColor
        style={{ color: jackColors.neutral900, marginTop: "8px" }}
      >
        {notes || "-"}
      </GothamRegular>

      <JackTransferButtonNav
        isDisabledCancel={isLoadingSubmitJackTransfer}
        isLoadingConfirm={isLoadingSubmitJackTransfer}
        confirmText="Submit"
        onCancel={handleBack}
        onConfirm={handleSubmit}
      />
    </div>
  );
};

export default ConfirmSubmit;
