import styles from "./styles.module.css";

const DollarTagInline = () => {
  return (
    <div className={styles.dollarTagInline}>
      <svg
        width="7"
        height="12"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 7.83333H1.22222C1.22222 8.49333 2.05944 9.05556 3.05556 9.05556C4.05167 9.05556 4.88889 8.49333 4.88889 7.83333C4.88889 7.16111 4.25333 6.91667 2.90889 6.59278C1.61333 6.26889 0 5.86556 0 4.16667C0 3.07278 0.898333 2.14389 2.13889 1.83222V0.5H3.97222V1.83222C5.21278 2.14389 6.11111 3.07278 6.11111 4.16667H4.88889C4.88889 3.50667 4.05167 2.94444 3.05556 2.94444C2.05944 2.94444 1.22222 3.50667 1.22222 4.16667C1.22222 4.83889 1.85778 5.08333 3.20222 5.40722C4.49778 5.73111 6.11111 6.13444 6.11111 7.83333C6.11111 8.92722 5.21278 9.85611 3.97222 10.1678V11.5H2.13889V10.1678C0.898333 9.85611 0 8.92722 0 7.83333Z"
          fill="#909098"
        />
      </svg>
    </div>
  );
};

export default DollarTagInline;
