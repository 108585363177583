import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { J2J_EVENT_CLICK_CHANGE } from "../../../constants";
import CompanyItem from "../../CompanyItem";
import styles from "./styles.module.css";

const SelectedCompany = ({
  withButton = true,
  company = {},
  style = {},
  itemStyle = {},
  onClick = () => {},
}) => {
  const handleClick = () => {
    onClick({});
    eventsTracker(J2J_EVENT_CLICK_CHANGE, {
      parameters: { page_title: "jack_transfer_recipient_account" },
      value: "select_recipient_balance_transfer",
    });
  };

  return (
    <div className={styles.selectedCompany} style={style}>
      <CompanyItem
        className={styles.companyItem}
        company={company}
        style={itemStyle}
      />
      {withButton && (
        <ButtonJack
          type="outline"
          style={{ maxWidth: "50px", padding: "0px", height: "32px" }}
          onClick={handleClick}
        >
          Change
        </ButtonJack>
      )}
    </div>
  );
};

export default SelectedCompany;
