import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useEffect } from "react";
import {
  CONFIRM_SUBMIT_TYPE,
  RECIPIENT_ACCOUNT_TYPE,
  TRANSFER_DETAILS_TYPE,
} from "../constants";
import { useHasRequiredCategory } from "../../categoryComponents/general/hooks";

const KICK_DELAY_PERIOD = 1000;

const useJackTransferKickDueToMissingData = ({ company, amount, category }) => {
  const { query, replace } = useRouter();
  const { form_type } = query;

  const hasRequiredCategory = useHasRequiredCategory({ category });

  const hasCompany = !isEmpty(company);
  const hasAmount = amount > 0;

  const kick = (to = RECIPIENT_ACCOUNT_TYPE) => {
    replace({ query: { ...query, form_type: to } });
  };

  useEffect(() => {
    const kickDelay = setTimeout(() => {
      switch (form_type) {
        case TRANSFER_DETAILS_TYPE:
          if (!hasCompany) kick();
          break;
        case CONFIRM_SUBMIT_TYPE:
          const isMissingDetails =
            hasCompany && (!hasRequiredCategory || !hasAmount);
          const isMissingAllData =
            !hasCompany && !hasRequiredCategory && !hasAmount;

          if (isMissingDetails) return kick(TRANSFER_DETAILS_TYPE);
          if (isMissingAllData) return kick();
          break;
        default:
          break;
      }
    }, [KICK_DELAY_PERIOD]);

    return () => clearTimeout(kickDelay);
  }, [company, form_type]);
};

export default useJackTransferKickDueToMissingData;
