import { useEffect, useState } from "react";
import { companyTagValidator } from "../helpers";
import { fetch } from "../../../tools/api";

const useFindCompany = ({ companyTag = "" }) => {
  const [isError, setIsError] = useState(false);

  const {
    data: company,
    loading,
    refetch,
    setData,
  } = fetch({
    url: `/balance_transfers/balance_tag?balance_tag=${companyTag}`,
    woInit: true,
    defaultValue: {},
    formatter: (res) => {
      const foundCompany = res?.data;
      if (!foundCompany) return {};
      return { tag: companyTag, name: foundCompany?.name };
    },
    errorHandler: () => {
      setIsError(true);
    },
  });

  useEffect(() => {
    const isValidValue = companyTagValidator(companyTag);
    if (!isValidValue) return;
    refetch();
  }, [companyTag]);

  useEffect(() => {
    if (isError) {
      setData({});
      setIsError(false);
      return;
    }
  }, [isError]);

  return { company, isLoadingCompany: loading };
};

export default useFindCompany;
