import { useEffect, useState } from "react";
import useJackTransferStoredData from "./useJackTransferStoredData";
import { isEmpty } from "lodash";

const defaultState = {
  company: {},
  amount: 0,
  notes: "",
  category: {},
};

const useJackTransferData = () => {
  const [hasAssignedStoredData, setHasAssignedStoredData] = useState(false);
  const [jackTransferData, setJackTransferData] = useState(defaultState);

  const { getJackTransferData } = useJackTransferStoredData();

  // To initialize data with stored data
  useEffect(() => {
    if (hasAssignedStoredData) return;
    const storedJackTransferData = getJackTransferData();
    const data = isEmpty(storedJackTransferData)
      ? defaultState
      : storedJackTransferData;
    setJackTransferData(data);
    setHasAssignedStoredData(true);
  }, [hasAssignedStoredData]);

  return { jackTransferData, hasAssignedStoredData, setJackTransferData };
};

export default useJackTransferData;
