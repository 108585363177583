import { jackColors } from "../../../../assets/colors";
import { TextFieldJackState } from "../../../../components/inputs/textfield";
import CategorySelection from "../../../categoryComponents/general/components/CategorySelection";
import {
  CONFIRM_SUBMIT_TYPE,
  J2J_EVENT_CLICK_CONTINUE,
  J2J_EVENT_CLICK_GO_BACK,
  RECIPIENT_ACCOUNT_TYPE,
} from "../../constants";
import {
  useJackTransferData,
  useJackTransferKickDueToMissingData,
  useJackTransferNavigation,
  useStoreJackTransferDetails,
} from "../../hooks";
import JackTransferButtonNav from "../JackTransferButtonNav";
import JackTransferTitle from "../JackTransferTitle";
import styles from "./styles.module.css";
import SelectedCompany from "../RecipientAccount/SelectedCompany";
import { useGetAuth } from "../../../../contexts/AuthContext";
import TransferAmountInput from "./TransferAmountInput";
import { useHasRequiredCategory } from "../../../categoryComponents/general/hooks";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { eventsTracker } from "../../../../universalFunctions/events";

const TransferDetails = () => {
  const { jackTransferData, hasAssignedStoredData, setJackTransferData } =
    useJackTransferData();
  const { company, amount, notes, category } = jackTransferData;

  useJackTransferKickDueToMissingData(jackTransferData);
  useStoreJackTransferDetails({
    data: jackTransferData,
    canStore: hasAssignedStoredData,
  });

  const { user } = useGetAuth();
  const { mainBalance } = user ?? {};
  const { balance } = mainBalance ?? {};

  const { loadingCategories } = useConstants();

  const hasRequiredCategory = useHasRequiredCategory({ category });

  const { onBack, onContinue } = useJackTransferNavigation({
    backDestination: RECIPIENT_ACCOUNT_TYPE,
    continueDestination: CONFIRM_SUBMIT_TYPE,
  });

  const handleChange = (key, value) => {
    setJackTransferData((prev) => ({ ...prev, [key]: value }));
  };
  const handleChangeNotes = (value) => {
    handleChange("notes", value);
  };
  const handleChangeAmount = (value) => {
    handleChange("amount", Number(value.split(",").join("")));
  };
  const handleSelectCategory = (category) => {
    handleChange("category", category);
  };

  const handleBack = () => {
    onBack();
    eventsTracker(J2J_EVENT_CLICK_GO_BACK, {
      parameters: { page_title: "jack_transfer_transfer_details" },
      value: "input_balance_transfer_details",
    });
  };

  const handleContinue = () => {
    onContinue();
    eventsTracker(J2J_EVENT_CLICK_CONTINUE, {
      parameters: { page_title: "jack_transfer_transfer_details" },
      value: "input_balance_transfer_details",
    });
  };

  const hasAmount = amount > 0;
  const hasEnoughBalance = balance >= amount;

  const isDisabledContinue =
    !hasAmount || !hasEnoughBalance || !hasRequiredCategory;

  return (
    <div className={styles.transferDetails}>
      <JackTransferTitle title="Input the transfer amount" />

      <SelectedCompany
        withButton={false}
        company={company}
        style={{
          border: "none",
          padding: "0px",
          margin: "0px",
          marginTop: "32px",
        }}
        itemStyle={{ padding: "0px" }}
      />

      <TransferAmountInput
        value={amount}
        balance={balance}
        onChange={handleChangeAmount}
      />

      <TextFieldJackState
        isTransparent
        withLetterCounter
        value={notes}
        maxLength={50}
        label="Transfer Notes"
        placeholder="Add transfer notes here"
        textFieldStyle={{ height: "60px" }}
        style={{ marginTop: "32px", marginBottom: "0px" }}
        letterCounterStyle={{ color: jackColors.neutral600 }}
        onChange={handleChangeNotes}
      />

      <CategorySelection
        selectedCategory={category ?? {}}
        style={{ margin: "0px", marginTop: "32px" }}
        onSelect={handleSelectCategory}
      />

      <JackTransferButtonNav
        isLoadingConfirm={loadingCategories}
        isDisabledConfirm={isDisabledContinue}
        onCancel={handleBack}
        onConfirm={handleContinue}
      />
    </div>
  );
};

export default TransferDetails;
