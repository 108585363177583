import styles from "./styles.module.css";

const SpinningLoading = () => {
  return (
    <div className={styles.spinningLoading}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="9.1665"
          width="1.66667"
          height="5"
          rx="0.833333"
          fill="#343434"
        />
        <rect
          y="10.8333"
          width="1.66667"
          height="5"
          rx="0.833333"
          transform="rotate(-90 0 10.8333)"
          fill="#909098"
        />
        <rect
          x="10.8335"
          y="20"
          width="1.66667"
          height="5"
          rx="0.833333"
          transform="rotate(180 10.8335 20)"
          fill="#BBBBC0"
        />
        <rect
          x="20"
          y="9.16666"
          width="1.66667"
          height="5"
          rx="0.833333"
          transform="rotate(90 20 9.16666)"
          fill="#909098"
        />
        <rect
          x="2.33984"
          y="3.5182"
          width="1.66667"
          height="5"
          rx="0.833333"
          transform="rotate(-45 2.33984 3.5182)"
          fill="#343434"
        />
        <rect
          x="3.51807"
          y="17.6603"
          width="1.66667"
          height="5"
          rx="0.833333"
          transform="rotate(-135 3.51807 17.6603)"
          fill="#343434"
        />
        <rect
          x="17.6602"
          y="16.4818"
          width="1.66667"
          height="5"
          rx="0.833333"
          transform="rotate(135 17.6602 16.4818)"
          fill="#343434"
        />
        <rect
          x="16.4819"
          y="2.33968"
          width="1.66667"
          height="5"
          rx="0.833333"
          transform="rotate(45 16.4819 2.33968)"
          fill="#343434"
        />
      </svg>
    </div>
  );
};

export default SpinningLoading;
