const companyTagValidator = (companyTag = "") => {
  const uniqueCode = companyTag?.slice(1, companyTag.length - 1);

  const isInvalidValue = isNaN(Number(uniqueCode));
  const isInvalidLength = companyTag?.length < 8;
  const isInvalidPrefix = companyTag[0]?.toLocaleLowerCase() !== "j";
  const isInvalidSuffix =
    companyTag[companyTag?.length - 1]?.toLocaleLowerCase() !== "x";

  return (
    !isInvalidValue && !isInvalidLength && !isInvalidPrefix && !isInvalidSuffix
  );
};

export default companyTagValidator;
