import { jackColors } from "../../../../assets/colors";
import { Avatar } from "../../../../components/Avatar";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import DollarTagInline from "./DollarTag";
import styles from "./styles.module.css";

const CompanyItem = ({
  className: classNameProp = "",
  company = { id: 0, tag: "", name: "" },
  style = {},
  onSelect = () => {},
}) => {
  const { tag, name } = company ?? {};

  const handleSelect = () => {
    onSelect(company);
  };

  const className = `${styles.companyItem} ${classNameProp}`;

  return (
    <div className={className} style={style} onClick={handleSelect}>
      <Avatar name={name} size="medium" />
      <div>
        <GothamMedium
          className="font12"
          style={{ color: jackColors.neutral900 }}
        >
          {name}
        </GothamMedium>
        <GothamRegular
          className="font12"
          style={{ color: jackColors.neutral700, marginTop: "4px" }}
        >
          <DollarTagInline />
          {tag}
        </GothamRegular>
      </div>
    </div>
  );
};

export default CompanyItem;
