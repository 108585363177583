import styles from "./styles.module.css";
import { jackColors } from "../../../../assets/colors";
import SimpleCenteredModal from "../../../../modals/SimpleCenteredModal";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../components/Text";
import ReactPlayer from "react-player";
import { eventsTracker } from "../../../../universalFunctions/events";
import { J2J_EVENT_CLICK_ARTICLE_URL } from "../../constants";

const JackTransferInfoModal = ({ isOpen = true, toggle = () => {} }) => {
  const handleClose = () => {
    toggle();
  };

  const handleClickURL = () => {
    eventsTracker(J2J_EVENT_CLICK_ARTICLE_URL, {
      parameters: { page_title: "jack_transfer_recipient_account" },
      value: "help_jackid",
    });
  };

  return (
    <SimpleCenteredModal
      isOpen={isOpen}
      leftHeaderComponent={
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900 }}
        >
          What is Jack ID?
        </GothamMedium>
      }
      buttonText="Got it"
      modalClassName={styles.jackTransferInfoModal}
      toggle={() => {
        handleClose();
      }}
      onClick={() => {
        handleClose();
      }}
    >
      <div className={styles.content}>
        <ReactPlayer
          loop
          muted
          playing
          width="476px"
          height="200px"
          url="/videos/jack-transfer-info.mov"
          style={{ overflow: "hidden", borderRadius: "8px" }}
        />

        <GothamMedium
          className="font20"
          style={{
            color: jackColors.neutral900,
            textAlign: "center",
            marginTop: "24px",
          }}
        >
          It's a company's unique code!
        </GothamMedium>

        <GothamRegular
          style={{ color: jackColors.neutral800, marginTop: "24px" }}
        >
          <TextInlineMedium>Jack ID</TextInlineMedium> is an 8 digits identifier
          that can be found on the top-right of company's balance section.
        </GothamRegular>

        <GothamRegular
          style={{ color: jackColors.neutral800, marginTop: "24px" }}
        >
          Need to send and receive money? Just use this code!
        </GothamRegular>

        <GothamRegular
          style={{ color: jackColors.neutral800, marginTop: "24px" }}
        >
          For more about Jack ID & transfer between Jack, please read{" "}
          <a
            target="_blank"
            href="https://support.itsjack.com/support/solutions/articles/150000107316-how-company-balance-works"
            className={styles.articleLink}
            onClick={handleClickURL}
          >
            this article.
          </a>
        </GothamRegular>
      </div>
    </SimpleCenteredModal>
  );
};

export default JackTransferInfoModal;
