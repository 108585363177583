import { useRouter } from "next/router";
import { useState } from "react";
import {
  CONFIRM_SUBMIT_TYPE,
  RECIPIENT_ACCOUNT_TYPE,
  TRANSFER_DETAILS_TYPE,
} from "../constants";
import useJackTransferStoredData from "./useJackTransferStoredData";

const useJackTransferNavigation = ({
  backDestination = "",
  continueDestination = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { query, back, replace } = useRouter();

  const { removeJackTransferData } = useJackTransferStoredData();

  const onContinue = () => {
    const isValidDestination = [
      TRANSFER_DETAILS_TYPE,
      CONFIRM_SUBMIT_TYPE,
    ].includes(continueDestination);

    if (!isValidDestination) return;

    const navigationDelay =
      continueDestination === TRANSFER_DETAILS_TYPE ? 2000 : 0;

    setIsLoading(true);
    setTimeout(() => {
      replace({ query: { ...query, form_type: continueDestination } });
    }, navigationDelay);
  };

  const onBack = () => {
    const isValidDestination = [
      "back",
      RECIPIENT_ACCOUNT_TYPE,
      TRANSFER_DETAILS_TYPE,
    ].includes(backDestination);

    if (!isValidDestination) return;

    const isBackGlobal = backDestination === "back";
    if (isBackGlobal) {
      removeJackTransferData();
      return back();
    }

    replace({ query: { ...query, form_type: backDestination } });
  };

  return { isLoading, onBack, onContinue };
};

export default useJackTransferNavigation;
