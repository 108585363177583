import { useEffect } from "react";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { useRouter } from "next/router";

const useJackTransferKickDueToUnauthorized = () => {
  const { back } = useRouter();
  const { userLoading } = useGetAuth();
  const { isDrafterBalanceManagement } = getUserRole();

  useEffect(() => {
    if (userLoading) return;
    if (!isDrafterBalanceManagement) back();
  }, [userLoading]);
};

export default useJackTransferKickDueToUnauthorized;
